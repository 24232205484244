<template>
  <vs-chip
    v-if="params.value"
    class="ag-grid-cell-chip"
    :color="chipColor(params.value)">
    <span :class="{'text-black': params.value === 3}">{{ chipText(params.value) }}</span>
  </vs-chip>
</template>

<script>
/**
 * Cell renderer type of feedback
 *
 * @module views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererRating
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {string} chipColor - color of chip
 * @vue-computed {string} chipText - text of chip
 */
export default {
  name: 'FeedbackAnalyticsTableCellRendererRating',
  i18n: {
    messages: {
      en: {
        Ratings: {
          ExtremelyDissatisfied: 'Extremely dissatisfied',
          Dissatisfied: 'Dissatisfied',
          Neutral: 'Neutral',
          Satisfied: 'Satisfied',
          ExtremelySatisfied: 'Extremely satisfied',
        },
      },
    },
  },
  computed: {
    chipText() {
      return (value) => {
        switch (value) {
          case 1:
            return `😠 ${this.$t('Ratings.ExtremelyDissatisfied')}`;
          case 2:
            return `😟 ${this.$t('Ratings.Dissatisfied')}`;
          case 3:
            return `😐 ${this.$t('Ratings.Neutral')}`;
          case 4:
            return `😊 ${this.$t('Ratings.Satisfied')}`;
          case 5:
            return `😍 ${this.$t('Ratings.ExtremelySatisfied')}`;

          default:
            return '';
        }
      };
    },
    chipColor() {
      return (value) => {
        switch (value) {
          case 1:
            return 'danger';
          case 2:
            return 'warning';
          case 3:
            return 'default';
          case 4:
            return 'primary';
          case 5:
            return 'success';

          default:
            return '';
        }
      };
    },
  },
};
</script>
