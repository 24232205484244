<template>
  <vs-chip
    v-if="params.value"
    class="ag-grid-cell-chip"
    :color="chipColor(params.value)">
    <feather-icon :icon="chipIcon(params.value)" svgClasses="h-5 w-5 mr-1" />
    <span>
      {{ $t(`$Enums.AppFeedbackType.${params.value}`) }}
    </span>
  </vs-chip>
</template>

<script>
/**
 * Cell renderer type of feedback
 *
 * @module views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererType
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {string} chipColor - color of chip
 * @vue-computed {string} chipIcon - icon of chip
 */
export default {
  name: 'FeedbackAnalyticsTableCellRendererType',
  computed: {
    chipColor() {
      return (value) => {
        switch (value) {
          case this.$enums.AppFeedbackType.EXPERIENCED_PROBLEM:
            return 'danger';
          case this.$enums.AppFeedbackType.NOT_FIND_SOMETHING:
            return 'warning';
          case this.$enums.AppFeedbackType.GIVE_US_COMPLIMENT:
            return 'primary';
          case this.$enums.AppFeedbackType.SUGGEST_IMPROVEMENT:
            return 'success';

          default:
            return '';
        }
      };
    },
    chipIcon() {
      return (value) => {
        switch (value) {
          case this.$enums.AppFeedbackType.EXPERIENCED_PROBLEM:
            return 'AlertTriangleIcon';
          case this.$enums.AppFeedbackType.NOT_FIND_SOMETHING:
            return 'SearchIcon';
          case this.$enums.AppFeedbackType.GIVE_US_COMPLIMENT:
            return 'HeartIcon';
          case this.$enums.AppFeedbackType.SUGGEST_IMPROVEMENT:
            return 'ChevronsUpIcon';

          default:
            return 'MinusIcon';
        }
      };
    },
  },
};
</script>
