<template>
  <div>
    <feedback-analytics-overview
      :feedback-data="feedbackPieChartData"/>

    <feedback-analytics-pie
      :series-data="feedbackPieChartData"
      class="mt-base"/>

    <feedback-analytics-table
      hide-updated-at-column
      hide-actions-column
      class="mt-base"/>
  </div>
</template>

<script>
// Components
import FeedbackAnalyticsTable from '@/views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTable.vue';
import FeedbackAnalyticsPie from '@/views/analytics/FeedbackAnalytics/FeedbackAnalyticsPie.vue';
import FeedbackAnalyticsOverview from '@/views/analytics/FeedbackAnalytics/FeedbackAnalyticsOverview.vue';

// Services
import feedbackService from '@/api/modules/feedback.service';

/**
 * Dashboard feedback analytics
 *
 * @module views/analytics/FeedbackAnalytics/FeedbackAnalytics
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object | null} feedbackPieChartData - pie chart data
 * @vue-event {void} getFeedbacksCount - get analytics pie data
 */
export default {
  name: 'DashboardFeedback',
  components: {
    FeedbackAnalyticsTable,
    FeedbackAnalyticsPie,
    FeedbackAnalyticsOverview,
  },
  data() {
    return {
      feedbackPieChartData: null,
    };
  },
  created() {
    this.getFeedbacksCount();
  },
  methods: {
    async getFeedbacksCount() {
      const resp = await feedbackService.getAnalyticsPie();
      this.feedbackPieChartData = resp.data;
    },
  },
};
</script>
