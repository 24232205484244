<template>
  <vx-card
    :title="$t('CardTitle')"
    collapse-action>
    <gs-pie-chart
      :series-info="feedbackPieSeriesInfo"
      :series-data="seriesData"
      :no-data-title="$t('NoData.Title')"
      :no-data-subtitle="$t('$NoData.Subtitle')"
      @chart-data="(data) => this.$emit('chart-data', data)"/>
  </vx-card>
</template>

<script>
// Components
import GsPieChart from '@/views/charts-and-maps/charts/GsPieChart.vue';

// Config
import themeConfig from '../../../../themeConfig';

/**
 * Component to show feedback analytics pie chart
 *
 * @module views/analytics/FeedbackAnalytics/FeedbackAnalyticsPie
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} seriesData - series data for pie chart
 * @vue-data {Object[]} feedbackPieSeriesInfo - series info
 */
export default {
  name: 'FeedbackAnalyticsPie',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Feedback comparison',
        $NoData: {
          Title: 'No feedback data to display',
          Subtitle: 'Wait for a customer to give you feedback',
        },
        $Ratings: {
          ExtremelyDissatisfied: 'Extremely dissatisfied',
          Dissatisfied: 'Dissatisfied',
          Neutral: 'Neutral',
          Satisfied: 'Satisfied',
          ExtremelySatisfied: 'Extremely satisfied',
        },
      },
    },
  },
  components: {
    GsPieChart,
  },
  props: {
    seriesData: {
      type: Object,
    },
  },
  data() {
    return {
      feedbackPieSeriesInfo: [
        {
          name: this.$t('$Ratings.ExtremelySatisfied'),
          key: '5',
          color: themeConfig.colors.success,
        },
        {
          name: this.$t('$Ratings.Satisfied'),
          key: '4',
          color: themeConfig.colors.primary,
        },
        {
          name: this.$t('$Ratings.Neutral'),
          key: '3',
          color: themeConfig.colors.grey,
        },
        {
          name: this.$t('$Ratings.Dissatisfied'),
          key: '2',
          color: themeConfig.colors.warning,
        },
        {
          name: this.$t('$Ratings.ExtremelyDissatisfied'),
          key: '1',
          color: themeConfig.colors.danger,
        },
      ],
    };
  },
};
</script>
