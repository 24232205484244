<template>
  <vx-card
    :title="$t('CardTitle')"
    collapse-action>
    <base-ag-grid-header
      :rows-selected-count="rowsSelectedCountWithAllSelection"
      :filters-count="filtersAppliedCount"
      :filters-match.sync="filtersMatch"
      @reset-selection="setSelectedRowsNone()"
      @reset-filters="resetFilters()">
      <template v-slot:header-left>
        <items-per-page
          :count="count"
          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize"
          @changed-page-size="paginationSetPageSize">
        </items-per-page>
      </template>
    </base-ag-grid-header>

    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @grid-size-changed="onGridSizeChanged"
      @first-data-rendered="onFirstDataRendered"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>
  </vx-card >
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';

// Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import CellRendererType from '@/views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererType.vue';
import CellRendererRating from '@/views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererRating.vue';
import CellRendererUser from '@/views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererUser.vue';
import CellRendererComments from '@/views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererComments.vue';

// Mixins
import commonAgGrid from '@/views/modules/_mixins/commonAgGrid';

// Services
import feedbackService from '@/api/modules/feedback.service';

/**
 * Component to show analytics list
 *
 * @module views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTable
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 */
export default {
  name: 'FeedbackAnalyticsTable',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Feedback history',
      },
    },
  },
  components: {
    BaseAgGridHeader,
    ItemsPerPage,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererType,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererRating,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererUser,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererComments,
  },
  mixins: [commonAgGrid],
  data() {
    return {
      columnDefs: [
        {
          colId: 'user',
          headerName: this.$tc('$General.User'),
          field: 'creator',
          filter: true,
          minWidth: 300,
          maxWidth: 700,
          checkboxSelection: false,
          headerComponentParams: { checkboxSelection: false },
          cellRendererFramework: 'CellRendererUser',
        },
        {
          colId: 'score',
          headerName: this.$t('$General.Rating'),
          field: 'score',
          filter: 'agNumberColumnFilter',
          minWidth: 250,
          maxWidth: 350,
          cellRendererFramework: 'CellRendererRating',
        },
        {
          colId: 'type',
          headerName: this.$tc('$General.Type'),
          field: 'type',
          filter: true,
          minWidth: 250,
          maxWidth: 350,
          cellRendererFramework: 'CellRendererType',
        },
        {
          colId: 'description',
          headerName: this.$tc('$General.Comment', 2),
          field: 'description',
          filter: true,
          minWidth: 250,
          maxWidth: 500,
          cellRendererFramework: 'CellRendererComments',
        },
      ],
      components: {
        CellRendererType,
        CellRendererRating,
        CellRendererUser,
        CellRendererComments,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  methods: {
    async fetchAgGridData(params) {
      const resp = await feedbackService.getAll({
        ...params,
        populate: [
          { path: 'creator', select: 'id name email' },
        ],
      });
      return resp.data;
    },
  },
};
</script>
