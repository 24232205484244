<template>
  <div>
    <div class="flex items-center">
      <div
        v-if="params.value"
        class="cursor-pointer"
        @click="showModal = true">
        {{ params.value }}
      </div>

      <a
        v-if="params.data && params.data.url"
        :href="params.data.url"
        target="_blank"
        class="d-inline-block ml-3">
        <feather-icon
          icon="ExternalLinkIcon"
          svgClasses="h-5 w-5 mr-1" />
      </a>
    </div>

    <vs-popup
      :title="headerName"
      :active.sync="showModal">
      <transition name="zoom-fade">
        <span>{{ params.value }}</span>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
/**
 * Cell renderer comments on feedback
 *
 * @module views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererComments
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} showModal - indicate if show modal or no
 * @vue-computed {string} headerName - name of header for modal
 */
export default {
  name: 'feedbackAnalyticsTableCellRendererComments',
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    headerName() {
      return this.params
        ? this.params.colDef.headerName
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  text-overflow: ellipsis;
  overflow-x: auto;
}
</style>
