<template>
  <div v-if="params.value">
    {{ params.value.name }} <em>({{ params.value.email }})</em>
  </div>
</template>

<script>
/**
 * Cell renderer type of feedback
 *
 * @module views/analytics/FeedbackAnalytics/table/FeedbackAnalyticsTableCellRendererUser
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'FeedbackAnalyticsTableCellRendererUser',
};
</script>
