<template>
  <vx-card
    :title="$t('CardTitle')"
    collapse-action>
    <div
      class="flex justify-around">
      <div>
        <h1 class="text-center text-5xl font-bold">{{ totalFeedbackCount }}</h1>
        <h3 class="text-center">
          {{ $tc('$General.Review', 2) | uppercase }}
        </h3>
      </div>

      <div>
        <h1 class="text-center text-5xl font-bold">{{ averageFeedback | fixed_number(1) }}</h1>
        <div class="flex justify-center">
          <star-rating
            :rating="averageFeedback"
            :increment="0.1"
            :round-start-rating="false"
            :show-rating="false"
            read-only
            :star-size="20"/>
        </div>
      </div>
    </div>

    <vs-divider class="my-base"></vs-divider>

    <div
      v-for="(feedback, index) in feedbackDataArray"
      :key="feedback.score"
      class="flex items-center justify-around"
      :class="{'mt-5': index > 0}">
      <div class="w-1/2">
        <h1 class="text-center text-3xl font-bold">{{ feedback.count }}</h1>
        <h4 class="text-center">
          {{ $t(`$Ratings.${feedback.score}`) | uppercase }}
        </h4>
      </div>

      <div class="w-1/2">
        <h1 class="text-center text-3xl font-bold">{{ feedback.count }}</h1>
        <star-rating
          class="justify-center"
          :rating="feedback.score"
          :increment="0.1"
          :round-start-rating="false"
          :show-rating="false"
          read-only
          :star-size="20"/>
      </div>
    </div>
  </vx-card>
</template>

<script>
import StarRating from 'vue-star-rating';

/**
 * Feedback analytics overview
 *
 * @module views/analytics/FeedbackAnalytics/FeedbackAnalyticsOverview
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} feedbackData - feedback data to show
 * @vue-computed {boolean} feedbackDataIsValid - indicate if feedbackPieChartData is valid
 * @vue-computed {Object[]} feedbackDataArray - feedback data as array of objects
 * @vue-computed {number} totalFeedbackCount - total feedback count
 * @vue-computed {number} averageFeedback - average feedback
 */
export default {
  name: 'FeedbackAnalyticsOverview',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Feedback overview',
        $Ratings: {
          1: 'Extremely dissatisfied',
          2: 'Dissatisfied',
          3: 'Neutral',
          4: 'Satisfied',
          5: 'Extremely satisfied',
        },
      },
    },
  },
  components: {
    StarRating,
  },
  props: {
    feedbackData: {
      required: true,
    },
  },
  computed: {
    feedbackDataIsValid() {
      return this.feedbackData
        && typeof this.feedbackData === 'object';
    },
    feedbackDataArray() {
      if (!this.feedbackDataIsValid) {
        return [];
      }

      return Object.entries(this.feedbackData).map(([key, value]) => ({
        score: Number(key),
        count: value,
      })).sort((a, b) => b.score - a.score);
    },
    totalFeedbackCount() {
      return this.feedbackDataIsValid
        ? Object.values(this.feedbackData).reduce(
          (previousValue, currentValue) => previousValue + currentValue, 0,
        ) : 0;
    },
    averageFeedback() {
      if (!this.feedbackDataIsValid) {
        return 0;
      }

      const total = Object.entries(this.feedbackData).reduce(
        (prevValue,
          [currentKey, currentValue]) => prevValue + (currentValue * currentKey),
        0,
      );

      return total / this.totalFeedbackCount;
    },
  },
};
</script>
